import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`So you also, when you have done everything you were told to do, should say,
‘We are unworthy servants; we have only done our duty.’ - Luke 17:10`}</p>
    </blockquote>
    <p>{`When a man reaches for praise as a reward for a good deed, is he not thinking he
has done something beyond his duty? Has he not, in a sense, cheapened an act by
trying to stand on top of it? Our duty is our privilege. Insincerity vulgarizes
its elevated nature. Donate to charity to alleviate suffering, not for
publicity. Defend and serve those who are disenfranchised for justice, not for
self-promotion.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      